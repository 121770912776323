export const GET_MOVIES = 'GET_MOVIES';
export const GET_MOVIES_BY_GENRE = 'GET_MOVIES_BY_GENRE';
export const GET_MOVIES_BY_PERSON = 'GET_MOVIES_BY_PERSON';
export const GET_MOVIE = 'GET_MOVIE';
export const GET_MOVIE_DETAILS_API = 'GET_MOVIE_DETAILS_API';
export const GET_MOVIE_CHOICES_API = 'GET_MOVIE_CHOICES_API';
export const GET_MOVIE_WITH_TMDBID_API = 'GET_MOVIE_WITH_TMDBID_API';
export const ADD_MOVIE = 'ADD_MOVIE';
export const DELETE_MOVIE = 'DELETE_MOVIE';
export const MOVIE_SEARCH_BY_TITLE = 'MOVIE_SEARCH_BY_TITLE';
export const MOVIES_LOADING = 'MOVIES_LOADING';

export const GET_MOVIENIGHTS = 'GET_MOVIENIGHTS';
export const GET_MOVIENIGHTS_BY_HOST = 'GET_MOVIENIGHTS_BY_HOST';
export const GET_MOVIENIGHT = 'GET_MOVIENIGHT';
export const GET_MOVIENIGHT_BY_MOVIE_VIEWED = 'GET_MOVIENIGHT_BY_MOVIE_VIEWED';
export const ADD_MOVIENIGHT = 'ADD_MOVIENIGHT';
export const DELETE_MOVIENIGHT = 'DELETE_MOVIENIGHT';
export const MOVIENIGHTS_LOADING = 'MOVIENIGHTS_LOADING';

export const GET_HOSTINGORDERS = 'GET_HOSTINGORDERS';
export const HOSTINGORDERS_LOADING = 'HOSTINGORDERS_LOADING';
export const GET_USER_DETAILS = 'GET_USER_DETAILS';
export const USER_DETAILS_LOADING = 'USER_DETAILS_LOADING';

export const GET_PERSON_WITH_TMDBID_API = 'GET_PERSON_WITH_TMDBID_API';
export const PERSONS_LOADING = 'PERSONS_LOADING';

export const GET_ERRORS = 'GET_ERRORS';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const GET_PROFILE = 'GET_PROFILE';
export const PROFILE_LOADING = 'PROFILE_LOADING';
export const PROFILE_NOT_FOUND = 'PROFILE_NOT_FOUND';
export const CLEAR_CURRENT_PROFILE = 'CLEAR_CURRENT_PROFILE';
export const GET_PROFILES = 'GET_PROFILES';
export const POST_LOADING = 'POST_LOADING';
export const GET_POSTS = 'GET_POSTS';
export const GET_POST = 'GET_POST';
export const ADD_POST = 'ADD_POST';
export const DELETE_POST = 'DELETE_POST';

export const GET_RELEASENOTES = 'GET_RELEASENOTES';
export const RELEASENOTES_LOADING = 'RELEASENOTES_LOADING';
